/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { Grid } from '../Grid';
import azCommonStyles from '../../theme/globals.module.scss';
import Skeleton from '../Skeleton/Skeleton';
import styles from './styles.module.scss';

const TwoColumnSkeleton = () => {
  const matchesTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const matchesMobile = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  return (
    <div
      className={cx(azCommonStyles.mainContentContainer, styles.pageContent, styles.shelfContent)}
    >
      <Skeleton
        data-testid="first-skeleton"
        animation="wave"
        width={matchesTablet ? '30%' : matchesMobile ? '60%' : '20%'}
      />
      <Skeleton
        animation="wave"
        height={50}
        width={matchesTablet ? '40%' : matchesMobile ? '70%' : '30%'}
      />
      <div className={styles.bottomSpacing} />
      <Grid container spacing={0}>
        <Grid item xs={12} md={12} lg={3} className={styles.hideMDDown}>
          <Skeleton animation="wave" height={50} width="80%" />
        </Grid>
        <Grid item xs={12} md={12} lg={9}>
          <Skeleton animation="wave" width="100%" height={800} variant="rect" />
        </Grid>
      </Grid>
    </div>
  );
};

export default TwoColumnSkeleton;
