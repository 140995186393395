/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useBrand } from '@/features/brands/api/getBrand';
import { useBrandProductData } from '@/features/brands/api/getProductBrandData';
import { usePageType } from '@/hooks/usePageType';
import { setCookie } from '@/utils/cookie';
import { useEffect, useRef, useState } from 'react';
import ErrorPage from '../ErrorPage';
import { ProductShelf } from '@/features/shelf';
import { useProductList } from '@/features/shelf/context/useProductList';
import TwoColumnSkeleton from '../Skeletons/TwoColumn';
import useRefDimensions from '@/hooks/useRefDimensions';
import { useRouter } from 'next/router';
import usePrevious from '@/utils/usePrevious';
import { useSelector } from 'react-redux';
import { ReduxState } from '@/types';
import { setFilterScrollBrand } from '@/actions/shelf/shelf';
import scrollToElem from '@/utils/crossBrowserHelpers/scroll';
import { useDispatch } from '@/hooks/redux/useDispatch';
import { useContentStackPLPData } from '@/features/productListingPage/api/getContentStackProductListingPageData';

export const BrandShelf = () => {
  const { pageType } = usePageType();
  const router = useRouter();
  const shelfRef = useRef<HTMLDivElement>(null);
  const mainShelfRef = useRef<HTMLDivElement>(null);
  const initialPageLoad = useRef(true);
  const {
    isError: csBrandShelfIsError,
    isSuccess: csBrandShelfIsSuccess,
    isCSProductListingPageEnabled,
  } = useContentStackPLPData({ enabled: true });
  const { isError: xmBrandShelfIsError, isSuccess: xmBrandShelfIsSuccess } = useBrand();
  const isSuccess = isCSProductListingPageEnabled ? csBrandShelfIsSuccess : xmBrandShelfIsSuccess;
  const isError = isCSProductListingPageEnabled ? csBrandShelfIsError : xmBrandShelfIsError;
  const { isError: brandProductError, isSuccess: brandProductSuccess } = useBrandProductData();
  const { isSuccess: shelfRequestSuccess } = useProductList();
  const { filterScrollBrand } = useSelector(({ shelf }: ReduxState) => shelf);
  const dispatch = useDispatch();

  const mainShelfDimensions = useRefDimensions(mainShelfRef);
  const [mainContainerHeight, setMainContainerHeight] = useState(mainShelfDimensions.height);
  const previousMainContainerHeight = usePrevious(mainContainerHeight);

  useEffect(() => {
    if (mainShelfRef.current) {
      if (resizeObserver) {
        resizeObserver.disconnect();
        resizeObserver.observe(mainShelfRef.current);
      }
    }

    if (
      shelfRef &&
      shelfRef.current &&
      filterScrollBrand &&
      !!router.query?.brandName &&
      shelfRequestSuccess &&
      !initialPageLoad.current
    ) {
      const top = shelfRef.current?.offsetTop - 200;
      scrollToElem(top);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, shelfRequestSuccess, shelfRef.current]);

  useEffect(() => {
    if (!!shelfRequestSuccess && !!isSuccess && !!initialPageLoad.current) {
      initialPageLoad.current = false;
      dispatch(setFilterScrollBrand(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shelfRequestSuccess, isSuccess]);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      setCookie('prevPageType', pageType || '');
    }
  }, [pageType]);

  let resizeObserver: ResizeObserver | undefined;

  try {
    resizeObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        entries.forEach((entry) => {
          if (entry.target.getBoundingClientRect().height !== previousMainContainerHeight) {
            setMainContainerHeight(entry.target.getBoundingClientRect().height);
          }
        });
      });
    });
  } catch (e) {
    resizeObserver = undefined;
  }

  if (isError || brandProductError) {
    return <ErrorPage />;
  }

  if (isSuccess && brandProductSuccess) {
    return <ProductShelf shelfRef={shelfRef} mainShelfRef={mainShelfRef} />;
  }

  return <TwoColumnSkeleton />;
};
